import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThanksPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} page="thanks">
      <div className="container">
        <SEO title="Thanks" />
        <h1 className="center">Thanks for subscribing!</h1>
        <p className="center">Stay well, friend. You’ll hear from us soon. &lt;3</p>
      </div>
    </Layout>
  )
}

export default ThanksPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
